.main-nav {
  background-color: rgb(251, 96, 93);
  text-align: center;
  padding: 12px 0px;
}

.navbar-brand img {
  max-height: 95.15px;
  max-width: 98vw;
  z-index: 999;
  position: relative;
}

.top-divider {
  background-image: url("./resources/topdivider.webp");
  background-size: 100% 75px;
  background-color: transparent;
  height: 75px;
  max-width: 100%;
  width: 100vw;
  position: absolute;          
  z-index: 1;
  transition: top 0.3s ease-out;
  top: 0;
}

.divider-top {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmYjYwNWQiPjxwYXRoIGQ9Ik0xMjgwIDE0MFYwSDBsMTI4MCAxNDB6IiBmaWxsLW9wYWNpdHk9Ii41Ii8+PHBhdGggZD0iTTEyODAgOThWMEgwbDEyODAgOTh6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 100% 100px;
  top: 0;
  height: 100px;
  width: 100%;
  z-index: 99;
  position: relative;
}

.divider-bottom {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmYjYwNWQiPjxwYXRoIGQ9Ik0wIDE0MGgxMjgwQzU3My4wOCAxNDAgMCAwIDAgMHoiIGZpbGwtb3BhY2l0eT0iLjMiLz48cGF0aCBkPSJNMCAxNDBoMTI4MEM1NzMuMDggMTQwIDAgMzAgMCAzMHoiIGZpbGwtb3BhY2l0eT0iLjUiLz48cGF0aCBkPSJNMCAxNDBoMTI4MEM1NzMuMDggMTQwIDAgNjAgMCA2MHoiLz48L2c+PC9zdmc+);
  background-size: 100% 100px;
  height: 100px;
  width: 100%;
  bottom: 0;
  z-index: 1;
  margin-top: -100px;
}

.divider-top-reverse {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmYjYwNWQiPjxwYXRoIGQ9Ik0xMjgwIDE0MFYwSDBsMTI4MCAxNDB6IiBmaWxsLW9wYWNpdHk9Ii41Ii8+PHBhdGggZD0iTTEyODAgOThWMEgwbDEyODAgOTh6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 100% 100px;
  transform: rotateY(180deg);
  top: 0;
  height: 100px;
  width: 100%;
  z-index: 99;
  position: relative;
}

.divider-bottom-reverse {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmYjYwNWQiPjxwYXRoIGQ9Ik0wIDE0MGgxMjgwQzU3My4wOCAxNDAgMCAwIDAgMHoiIGZpbGwtb3BhY2l0eT0iLjMiLz48cGF0aCBkPSJNMCAxNDBoMTI4MEM1NzMuMDggMTQwIDAgMzAgMCAzMHoiIGZpbGwtb3BhY2l0eT0iLjUiLz48cGF0aCBkPSJNMCAxNDBoMTI4MEM1NzMuMDggMTQwIDAgNjAgMCA2MHoiLz48L2c+PC9zdmc+);
  background-size: 100% 100px;
  transform: rotateY(180deg);
  height: 100px;
  width: 100%;
  bottom: 0;
  z-index: 1;
  margin-top: -100px;
}

.divider-top.down,
.divider-top-reverse.down {
  margin-bottom: -100px;
  z-index: 2;
}

.divider-footer {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMnZ3IiB2aWV3Qm94PSIwIDAgMTI4MCAxNDAiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iIzAwMDAwMCI+PHBhdGggZD0iTTEyODAgMy40QzEwNTAuNTkgMTggMTAxOS40IDg0Ljg5IDczNC40MiA4NC44OWMtMzIwIDAtMzIwLTg0LjMtNjQwLTg0LjNDNTkuNC41OSAyOC4yIDEuNiAwIDMuNFYxNDBoMTI4MHoiIGZpbGwtb3BhY2l0eT0iLjMiLz48cGF0aCBkPSJNMCAyNC4zMWM0My40Ni01LjY5IDk0LjU2LTkuMjUgMTU4LjQyLTkuMjUgMzIwIDAgMzIwIDg5LjI0IDY0MCA4OS4yNCAyNTYuMTMgMCAzMDcuMjgtNTcuMTYgNDgxLjU4LTgwVjE0MEgweiIgZmlsbC1vcGFjaXR5PSIuNSIvPjxwYXRoIGQ9Ik0xMjgwIDUxLjc2Yy0yMDEgMTIuNDktMjQyLjQzIDUzLjQtNTEzLjU4IDUzLjQtMzIwIDAtMzIwLTU3LTY0MC01Ny00OC44NS4wMS05MC4yMSAxLjM1LTEyNi40MiAzLjZWMTQwaDEyODB6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 100% 15vw;
  bottom: 0;
  height: 15vw;
  z-index: 1;
  margin-top: -15vw;
  transform: rotateY(180deg);
}

.splash-wrapper {
  width: 100%;
  height: 100vh;
  background-image: url('./resources/UndergroundCastle.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.splash {
  display: flex;
  text-align: center;
  max-width: 1200px;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.splash:not(:last-child) {
  margin-bottom: 32px;

}

.ftc-button {
  background-color: rgb(251, 96, 93);
  border: 3px solid rgb(57, 57, 57);
  color: rgb(57, 57, 57);
  text-align: center;
  line-height: 30.6px;
  border-radius: 100px;
  letter-spacing: 1px;

  min-width: 220px;
  
  font-size: 18px !important;
  
  font-family: 'Rubik',Helvetica,Arial,Lucida,sans-serif !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  padding-right: 40px !important;
  padding-left: 54px !important;

  margin: 14px;

  transition: padding 0.5s ease-in-out;
}

.ftc-button:hover {
  color: white;
  border-color: white;
  background-color: rgb(201, 46, 43);
}

.ftc-button.white {
  background-color: white;
  border-color: white;
  color: rgb(57, 57, 57);
}

.ftc-button.white:hover {
  background-color: rgb(57, 57, 57);
  border-color: rgb(57, 57, 57);
  color: white;
}

.servers-wrapper {
  background-image: url("./resources/minecraft-night.webp");
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding-bottom: 36px;
}

.server-tagline {
  display: flex;
  flex-direction: row;
  width: 100%;
  color: white;
  display: flex;
  max-width: 1200px;
  margin: 24px;
  flex: 1;
}

.server-details {
  padding: 14px;
}

.server-server-name {
  font-family: 'Rubik',Helvetica,Arial,Lucida,sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
  color: #fb605d !important;
  letter-spacing: 2px;
}

.server-image {
  width: 250px;
  height: 250px;
}

.final-section {
  background-image: url('./resources//MinecraftBreakInTheClouds.webp');
  background-size: cover;
  min-height: 800px;
}

.community-call-to-action {
  background-image: linear-gradient(185deg,#fb605d 0%,#3d0404 100%);
  border-radius: 0 0 3vw 0;
  overflow: hidden;
  max-width: 100%;
  width: 33vw;
  z-index: 999;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 64px;
}

.cta-title {
  font-family: 'Rubik',Helvetica,Arial,Lucida,sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 44px;
  letter-spacing: 1px;
  line-height: 1.3em;
  color: white;
}

.cta-description {
  font-size: 100%;
  vertical-align: baseline;
  color: white;
  background: transparent;
  max-width: 100%;
}

.app-footer {
  background-color: rgba(0, 0, 0, 0.89);
  color: rgb(102, 102, 102);
  padding: 15px 5px;
  display: flex;
  justify-content: center;
}

.footer-content {
  text-align: left;
  max-width: 1200px;
  width: 100%;
}

@media only screen and (max-width: 990px) {
  .top-divider {
    background-size: 100% 5vw;
    height: 5vw;
  }

  .server-tagline {
    justify-content: center;
  }

  .server-details {
    display: none;
  }

  .community-call-to-action {
    width: min-content;
    padding: 14px;;
  }

  .community-call-to-action div {
    width: calc(100% - 29px);
  }
}